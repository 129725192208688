@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

html,
body,
#root {
    width: 100%;
    height: 100%;
}

body {
    display: flex;
    justify-content: center;

    margin: 0;

    font-family: 'Poppins';
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

#root {
    max-width: 480px;
}
